'use client';

import classNames from 'classnames';
import styles from './btn-hamburger.module.css';
import { GoogleAnalyticsData } from '@/types/globals';

export interface BtnHamburgerProps {
	/** Allows us to add extra styling properties onto the button. */
	className?: string;

	/** Keeps track of the current state of the navigation menu (open or closed). */
	navOpen: boolean;

	/** Allows us to set the state of the navigation menu. */
	setNavOpen: React.Dispatch<React.SetStateAction<boolean>>;

	data?: GoogleAnalyticsData;

	navDarkVariant?: boolean;
}

/** This is the button used for the navigation menu on the mobile version of HVAC.com. */
const BtnHamburger = (props: BtnHamburgerProps) => {
	const {
		className,
		navOpen,
		setNavOpen,
		data = { testId: 'BtnHamburger' },
		navDarkVariant,
	} = props;

	const handleToggle = () => {
		setNavOpen((prevNavOpen) => !prevNavOpen);
	};

	return (
		<button
			data-testid={data.testId}
			aria-expanded="false"
			onClick={handleToggle}
			className={classNames(
				'group',
				styles['header__menu-btn'],

				styles['header__menu-btn--transition'],
				'relative z-10 inline-block h-6 w-6 cursor-pointer',
				{
					[styles['is-visible']]: navOpen, // Apply "is-open" class if toggleRef.current is true
				},

				className,
			)}
		>
			<span
				className={classNames(
					styles['header__menu-icon'],
					'relative mx-auto my-0 block w-full',
					{
						'group-hover:bg-transparent': navOpen,
						'!bg-transparent': navOpen,
						'before:!bg-black after:!bg-black': navOpen,
						'group-hover:before:!bg-sky-800 group-hover:after:!bg-sky-800':
							navOpen,
					},
					'bg-black',
					'before:bg-black',
					'after:bg-black',
					{
						'bg-white before:bg-white after:bg-white': navDarkVariant,
					},
				)}
			></span>
			<span className="sr-only">Toggle Navigation Menu</span>
		</button>
	);
};

export default BtnHamburger;

import classNames from 'classnames';
import Link from 'next/link';
import { GoogleAnalyticsData } from '@/types/globals';

export interface LinkInstagramProps {
	/** For internal testing using Cypress. */
	data?: GoogleAnalyticsData;

	/** Allows us to add extra styling properties onto the button. */
	className?: string;
}

/** This is the icon that links to the HVAC Instagram. */
const LinkInstagram = (props: LinkInstagramProps) => {
	const { className, data = { testId: 'LinkInstagram' } } = props;

	return (
		<Link
			href={`https://www.instagram.com/itshvacdotcom/`}
			rel="noopener"
			target="_blank"
			data-testid={data.testId}
		>
			<svg
				className={classNames(
					'fill-slate-400',
					'hover:fill-sky-800',
					className,
				)}
				xmlns="http://www.w3.org/2000/svg"
				fill="#FFF"
				viewBox="0 0 22 22"
				role="img"
				aria-label="Instagram"
			>
				<path d="M11.002 0C8.014 0 7.638.013 6.465.066a8.071 8.071 0 0 0-2.67.512c-.734.275-1.399.708-1.947 1.268A5.417 5.417 0 0 0 .576 3.794a8.042 8.042 0 0 0-.51 2.671C.014 7.638 0 8.012 0 11c0 2.988.013 3.36.066 4.535a8.07 8.07 0 0 0 .512 2.67 5.392 5.392 0 0 0 1.268 1.947c.549.56 1.214.994 1.948 1.271.855.32 1.758.493 2.671.51C7.638 21.989 8.012 22 11 22c2.988 0 3.36-.012 4.535-.066a8.086 8.086 0 0 0 2.671-.51A5.647 5.647 0 0 0 21.43 18.2a8.164 8.164 0 0 0 .512-2.67c.052-1.172.066-1.546.066-4.535 0-2.988-.014-3.362-.066-4.535a8.176 8.176 0 0 0-.512-2.67 5.39 5.39 0 0 0-1.27-1.949A5.33 5.33 0 0 0 18.213.575a8.082 8.082 0 0 0-2.679-.509C14.36.013 13.986 0 10.998 0h.004Zm-.989 1.982h.989c2.936 0 3.283.01 4.443.064a6.088 6.088 0 0 1 2.043.378c.478.177.91.458 1.264.824.365.355.646.786.824 1.264.242.654.37 1.345.378 2.043.053 1.158.064 1.507.064 4.443s-.01 3.284-.064 4.444a6.064 6.064 0 0 1-.378 2.04 3.643 3.643 0 0 1-2.088 2.087 6.022 6.022 0 0 1-2.043.38c-1.158.053-1.507.064-4.443.064s-3.286-.011-4.446-.065a6.088 6.088 0 0 1-2.042-.38 3.401 3.401 0 0 1-1.266-.821 3.402 3.402 0 0 1-.822-1.264 6.087 6.087 0 0 1-.38-2.043c-.052-1.16-.062-1.507-.062-4.445 0-2.938.01-3.284.062-4.444a6.033 6.033 0 0 1 .38-2.043c.176-.477.457-.91.822-1.264a3.423 3.423 0 0 1 1.266-.823 6.127 6.127 0 0 1 2.042-.38c1.015-.045 1.408-.06 3.458-.06Zm6.86 1.826a1.322 1.322 0 1 0 0 2.643 1.322 1.322 0 0 0 0-2.643Zm-5.871 1.543a5.649 5.649 0 1 0 0 11.297 5.649 5.649 0 0 0 0-11.297Zm0 1.982A3.666 3.666 0 1 1 7.34 11a3.667 3.667 0 0 1 3.662-3.66v-.007Z" />
			</svg>
		</Link>
	);
};

export default LinkInstagram;

import classNames from 'classnames';
import Link from 'next/link';
import { GoogleAnalyticsData } from '@/types/globals';

export interface LinkFacebookProps {
	/** For internal testing using Cypress. */
	data?: GoogleAnalyticsData;

	/** Allows us to add extra styling properties onto the button. */
	className?: string;
}

/** This is the icon that links to the HVAC Facebook. */
const LinkFacebook = (props: LinkFacebookProps) => {
	const { className, data = { testId: 'LinkFacebook' } } = props;

	return (
		<Link
			href={`https://facebook.com/.itshvacdotcom`}
			rel="noopener"
			target="_blank"
			data-testid={data.testId}
		>
			<svg
				className={classNames(
					'fill-slate-400',
					'hover:fill-sky-800',
					className,
				)}
				xmlns="http://www.w3.org/2000/svg"
				fill="#FFF"
				viewBox="0 0 22 22"
				role="img"
				aria-label="Facebook"
			>
				<path d="M5.355 11.717h2.411v9.928c0 .196.16.355.355.355h4.09a.355.355 0 0 0 .354-.355v-9.881h2.772c.18 0 .332-.135.353-.314l.42-3.655a.355.355 0 0 0-.352-.396h-3.193V5.11c0-.692.372-1.042 1.106-1.042h2.087a.355.355 0 0 0 .355-.355V.357a.355.355 0 0 0-.355-.354h-2.877A2.823 2.823 0 0 0 12.749 0c-.5 0-2.235.098-3.605 1.36-1.52 1.397-1.308 3.07-1.258 3.36V7.4H5.355A.355.355 0 0 0 5 7.753v3.608c0 .196.159.355.355.355Z" />
			</svg>
		</Link>
	);
};

export default LinkFacebook;

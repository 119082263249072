'use client';

import profiler from '@/helpers/userProfile';

/**
 * Sets user properties in Heap analytics.
 *
 * @param {Record<string, string>} properties - An object containing key-value pairs of user properties to set.
 * @returns {boolean} - Returns true if the properties were successfully set, otherwise false.
 *
 * @example
 * // Use with static properties
 * setHeapPropsClientSide({
 * 	SurveyQuestion: 'Would you pay $10/month for this?',
 * 	SurveyAnswer: 'Yes',
 * });
 * // Use with dynamic properties
 * setHeapPropsClientSide({
 *  [`Article ${article.id}`]: 'Viewed',
 * });
 */
export const setHeapPropsClientSide = (properties: Record<string, string>) => {
	// Ensure heap is present
	if (!window.heap) return false;

	const [userProfile] = profiler();
	const id = userProfile?.mtaAid;

	window.heap.addUserProperties({
		MTAAnonymousID: id,
		...properties,
	});
	window.heap.identify(id);

	return true;
};

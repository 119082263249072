'use client';

import { useEffect } from 'react';

const HubSpotChat = () => {
	useEffect(() => {
		window.hsConversationsSettings = {
			loadImmediately: false,
		};

		setTimeout(() => {
			if (window.HubSpotConversations) {
				window.HubSpotConversations?.on('widgetClosed', () => {
					const otherChats = window.location.search.includes('?');
					if (otherChats) {
						window.history.pushState({}, '', '?chat=off');
					}
					window.HubSpotConversations.widget.remove();
				});
			}
		}, 1000);
	}, []);

	return null;
};

export default HubSpotChat;

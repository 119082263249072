import cn from 'classnames';
import { GoogleAnalyticsData } from '@/types/globals';
import type { Variant } from './button.d';
import { getButtonClassname } from './helpers/getButtonClassname';

export interface ButtonImageInputProps {
	/** Allows us to change the text of the button. */
	children: React.ReactNode;

	/** Allows us to add extra styling properties onto the button. */
	className?: string;

	/** Allows us to add extra styling properties onto the div wrapping the button. */
	wrapperClassName?: string;

	/** For a unique target for each image input */
	name: string;

	/** Causes the website to update when the image input is interacted with */
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

	/** Gives us different stylings, hover, and focus states of the button. */
	variant?: Variant;

	/** Allows us to set an enabled (clickable) or disabled (unclickable) state for the button. */
	disabled?: boolean;

	/** For internal testing and analytics */
	data?: GoogleAnalyticsData;

	/** */
	inputAttributes?: Omit<
		React.InputHTMLAttributes<HTMLInputElement>,
		'type' | 'name' | 'id' | 'accept' | 'multiple' | 'onChange' | 'className'
	>;
}

/** For image inputs that need to look like buttons */
const ButtonImageInput = (props: ButtonImageInputProps) => {
	const {
		children,
		className = '',
		wrapperClassName = '',
		variant = 'solid',
		name,
		onChange,
		disabled = false,
		data = { testId: 'ButtonImageInput' },
		inputAttributes,
	} = props;

	const classes = getButtonClassname(variant, disabled, className);

	const manInTheMiddleOnChange = function (
		event: React.ChangeEvent<HTMLInputElement>,
	) {
		onChange(event);

		event.currentTarget.value = '';
	};

	return (
		<div
			data-testid={data.testId}
			data-action={data.action}
			data-location={data.location}
			data-text={data.text}
			data-postion={data.position}
			data-type={data.type}
			data-textgrouping={data.textGrouping}
			className={cn('flex', wrapperClassName)}
		>
			<label htmlFor="quote" className={classes}>
				{children}
			</label>
			<input
				type="file"
				name={name}
				id="quote"
				accept="application/pdf, image/png, image/jpeg, image/webp, image/heic, environment"
				multiple
				onChange={manInTheMiddleOnChange}
				className="hidden"
				{...inputAttributes}
			/>
		</div>
	);
};

export default ButtonImageInput;

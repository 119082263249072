const openChat = () => {
	// The option widgetOpen only works when you laod the widget with the page
	window.HubSpotConversations?.widget.load();

	// In order to open the widget when clicking our buttons, we need to wait for it to be loaded
	// and then run this option with a timeout
	window.HubSpotConversations?.on('widgetLoaded', () => {
		window.HubSpotConversations?.widget.open();
	});
};

export default openChat;

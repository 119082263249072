import cn from 'classnames';
import styles from './button.module.css';
import { GoogleAnalyticsData } from '@/types/globals';

export interface ButtonFilterProps {
	/** Allows us to change the text of the button. */
	children: React.ReactNode;

	/** For the status of checked or unchecked */
	checked: boolean;

	/** For a unique target for each checkbox */
	name: string;

	/** Causes the website to update when the checkbox is interacted with */
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

	/** For mapping each checkbox to the name */
	value: string;

	/** For internal testing and analytics */
	data?: GoogleAnalyticsData;
}

/** For checkboxes that need to look like buttons */
const ButtonFilter = (props: ButtonFilterProps) => {
	const {
		children,
		checked,
		name,
		onChange,
		value,
		data = { testId: 'ButtonFilter' },
	} = props;

	return (
		<div
			data-testid={data.testId}
			data-action={data.action}
			data-location={data.location}
			data-text={data.text}
			data-postion={data.position}
			data-type={data.type}
			data-textgrouping={data.textGrouping}
		>
			<input
				name={name}
				value={value}
				type="checkbox"
				checked={checked}
				onChange={onChange}
				className="hidden"
				id={name}
			/>
			<label
				className={cn(styles['filter-button'], {
					[styles['filter-button-checked']]: checked,
				})}
				htmlFor={name}
			>
				{children}
			</label>
		</div>
	);
};

export default ButtonFilter;

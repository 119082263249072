'use client';

import { useState } from 'react';
import { GoogleAnalyticsData } from '@/types/globals';

export interface HvacLogoReverseProps {
	data?: GoogleAnalyticsData;
	className?: string;
}

const HvacLogoReverse = (props: HvacLogoReverseProps) => {
	const { data = { testId: 'HvacLogoReverse' }, className } = props;
	const [isHovered, setIsHovered] = useState(false);

	return (
		<svg
			data-testid={data.testId}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 129 36"
			className={className}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			aria-label="HVAC Logo"
		>
			<path
				fill={isHovered ? 'url(#hvac_logo_reverse)' : '#000'}
				fillRule="evenodd"
				d="M53.702 21.653V8.95c0-4.935 2.915-8.95 9.228-8.95 6.022 0 8.936 4.354 8.936 9.579v2.563h-6.022V9.434c0-2.95-1.36-4.208-2.914-4.208-2.428 0-3.205 1.257-3.205 3.87V26.8c0 2.612.777 3.87 3.205 3.87 2.185 0 2.914-1.258 2.914-3.532V22.93h6.022v4.016c0 4.934-2.914 8.95-8.936 8.95-3.236 0-5.58-1.055-7.094-2.748l-2.134-11.495Zm38.26.578h-4.237v-.53c0-2.346-.833-3.329-2.346-3.329-1.513 0-2.345.983-2.345 3.329v7.49c0 2.345.832 3.329 2.345 3.329 1.513 0 2.346-.984 2.346-3.33v-1.285h4.236v.53c0 4.463-1.74 7.565-6.582 7.565s-6.582-3.102-6.582-7.566v-5.977c0-4.463 1.74-7.565 6.582-7.565 4.766 0 6.507 2.988 6.582 7.338Zm9.923-.53v7.49c0 2.345-.832 3.329-2.345 3.329-1.514 0-2.346-.984-2.346-3.33V21.7c0-2.345.832-3.328 2.346-3.328 1.513 0 2.345.983 2.345 3.329Zm-8.928.756v5.977c0 4.464 1.74 7.566 6.583 7.566 4.842 0 6.582-3.102 6.582-7.566v-5.977c0-4.463-1.74-7.565-6.582-7.565s-6.583 3.102-6.583 7.565Zm22.824 13.165h4.237V19.658c.945-.719 1.777-1.286 2.685-1.286.833 0 1.513.454 1.513 1.475v15.775h4.237V19.166c0-2.648-1.324-4.274-3.972-4.274-1.551 0-2.686.454-4.728 2.42-.908-1.664-1.589-2.42-3.707-2.42-1.864 0-2.78.784-4.328 2.11l-.363.31V15.27h-4.01v20.352h4.237V19.658c.983-.719 1.816-1.286 2.723-1.286.833 0 1.476.454 1.476 1.475v15.775Zm-42.886 0h4.464v-4.464h-4.464v4.464Zm-32.173-.064 4.336-27.96h.097l4.315 27.96h5.851L48.84.63h-7.467L34.91 35.558h5.812ZM19.4 5.95l6.217 29.608h6.022L38.972.63h-5.924l-4.371 24.237h-.098L24.21.63H19.4v5.32ZM0 35.558h6.022v-15.48h6.41v15.48h6.023V.63h-6.022v14.223h-6.41V.63H0v34.928Z"
				clipRule="evenodd"
			/>
			<defs>
				<linearGradient
					id="hvac_logo_reverse"
					x1="39"
					x2="96.375"
					y1="-34.5"
					y2="67.125"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset=".5" stopColor="#EC4899" />
					<stop offset="1" stopColor="#0EA5E9" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default HvacLogoReverse;

import profiler from '../userProfile';

const STORAGE_KEY = 'zipcode';

interface LocationInfo {
	city: string;
	state: string;
	postalCode: string;
}

export const initializeLocation = async (): Promise<string | null> => {
	let mistZip = localStorage.getItem(STORAGE_KEY);
	const [userProfile] = profiler();

	// If we're missing any data (either in profile or in localStorage), fetch it
	if (!userProfile.city || !userProfile.state || !userProfile.zip || !mistZip) {
		const data = await getLocationInfo();
		if (data) {
			setMistZip(data.postalCode);
			updateProfile(data.city, data.state, data.postalCode);
			mistZip = data.postalCode;
		}
	}

	return mistZip;
};

/**
 * Updates the user profile with city, state, and zip if they don't already exist.
 * @param city - The city value to set.
 * @param state - The state value to set.
 * @param zip - The zip value to set.
 */
function updateProfile(city: string, state: string, zip: string) {
	const [, updateProfile] = profiler();
	updateProfile('city', city);
	updateProfile('state', state);
	updateProfile('zip', zip);
}

/**
 * Sets the zip value in local storage if it doesn't already exist.
 * @param zip - The zip value to set.
 */
async function setMistZip(zip: string) {
	const mistZip = localStorage.getItem(STORAGE_KEY);
	if (!mistZip) {
		localStorage.setItem(STORAGE_KEY, zip);
	}
}

export const getLocationInfo = async (): Promise<LocationInfo | null> => {
	const mistApi =
		process.env.NEXT_PUBLIC_ENVIRONMENT === 'prd'
			? 'https://mist.mtechapis.com'
			: 'https://mist-stg.mtechapis.com';

	try {
		const response = await fetch(mistApi);
		const data = await response.json();
		return data;
	} catch (error) {
		console.error(error);
		return null;
	}
};

import openChat from '@/helpers/hubspot/openChat';
import classNames from 'classnames';

interface ChatButtonProps {
	navOpen?: boolean;
	variant?: 'nav' | 'progress-bar';
	navDarkVariant?: boolean;
}

export const ChatButton = (props: ChatButtonProps) => {
	const { navOpen, variant = 'nav', navDarkVariant } = props;
	const isNav = variant === 'nav';
	const isProgress = variant === 'progress-bar';

	return (
		<button
			className={classNames(
				{ 'xl:hidden': isNav },
				{ 'hidden xl:block': isProgress },
				'rounded-full border-2 border-slate-300 px-4 py-2 font-semibold text-slate-500 hover:border-slate-400',
				// to hide behind open nav panel when open
				{ '-z-10': navOpen },
				{
					'border-white text-white': navDarkVariant,
				},
				'transition-all',
			)}
			onClick={openChat}
		>
			Chat with us
		</button>
	);
};

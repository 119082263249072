import { TreatmentsWithConfig } from './types';

export const splitSwitcher = <T extends string>(
	flag: T[],
	treatment: string,
): TreatmentsWithConfig<T> => {
	return flag.reduce((treats, flagName) => {
		treats[flagName] = {
			treatment: treatment,
			config: JSON.stringify({
				message:
					'This is a custom dev treatment and not indicative of what is in Split',
			}),
		};
		return treats;
	}, {} as TreatmentsWithConfig<T>);
};

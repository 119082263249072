import { GoogleAnalyticsData } from '@/types/globals';
import { getButtonClassname } from './helpers/getButtonClassname';
import { Variant } from './button.d';
export interface ButtonContactProps {
	/** Allows us to change the text of the button. */
	children: React.ReactNode;

	/** Allows us to add extra styling properties onto the button. */
	className?: string;

	/** Allows us to add a hyperlink to the button. */
	href: string;

	/** Gives us different stylings, hover, and focus states of the button. */
	variant?: Variant;

	/** Allows us to set an enabled (clickable) or disabled (unclickable) state for the button. */
	disabled?: boolean;

	/** For internal testing and analytics */
	data?: GoogleAnalyticsData;
}

/** For contact links that need to look like buttons (sms, mailto, tel) */
const ButtonContact = (props: ButtonContactProps) => {
	const {
		children,
		className = '',
		href,
		variant = 'solid',
		disabled = false,
		data = { testId: 'ButtonContact' },
	} = props;

	const classes = getButtonClassname(variant, disabled, className);

	return (
		<a
			data-testid={data.testId}
			data-action={data.action}
			data-location={data.location}
			data-text={data.text}
			data-postion={data.position}
			data-type={data.type}
			data-textgrouping={data.textGrouping}
			className={classes}
			href={href}
		>
			{children}
		</a>
	);
};

export default ButtonContact;

import Link from 'next/link';
import { FooterLinkItem } from './types';
import cn from 'classnames';

export interface FooterLinkProps {
	testId?: string;
	link: FooterLinkItem;
}

const FooterLink = (props: FooterLinkProps) => {
	const { testId = 'FooterLink', link } = props;

	if (link.href && !link.callback) {
		return (
			<li
				data-testid={`${testId}-${link.label}`}
				className={cn('-xs:leading-4', link.className)}
			>
				<Link
					href={link.href}
					className={cn(
						'text-base text-black',
						'leading-none',
						'tracking-tight',
						'hover:text-sky-800',
					)}
				>
					{link.label}
				</Link>
			</li>
		);
	}

	if (link.callback) {
		return (
			<li
				data-testid={`${testId}-${link.label}`}
				className={cn('-xs:leading-4', link.className)}
			>
				<span
					className={cn(
						'text-base text-black',
						'leading-none',
						'tracking-tight',
						'hover:cursor-pointer hover:text-sky-800',
					)}
					onClick={link.callback}
				>
					{link.label}
				</span>
			</li>
		);
	}

	return null;
};

export default FooterLink;

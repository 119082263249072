import { LinkFacebook } from '../LinkFacebook';
import { LinkInstagram } from '../LinkInstagram';
import { LinkTiktok } from '../LinkTiktok';

import cn from 'classnames';

export interface SocialLinksProps {
	testId?: string;
	containerClass: string;
}

const SocialLinks = (props: SocialLinksProps) => {
	const { testId = 'SocialLinks', containerClass } = props;

	return (
		<ul
			data-testid={testId}
			className={cn('flex items-center gap-6', containerClass)}
		>
			<li>
				<LinkTiktok className="h-4.5 w-4.5" />
			</li>
			<li>
				<LinkInstagram className="h-4.5 w-4.5" />
			</li>
			<li>
				<LinkFacebook className="h-4.5 w-4.5" />
			</li>
		</ul>
	);
};

export default SocialLinks;

'use client';

import { TopBannerWithLinkConfig } from './TopBanner';

function hasWhitelist(pagesWhitelist?: string[]): boolean {
	return Boolean(pagesWhitelist && pagesWhitelist.length);
}

function hasBlacklist(pagesBlacklist?: string[]): boolean {
	return Boolean(pagesBlacklist && pagesBlacklist.length);
}

function isPathInWhitelist(path: string, pagesWhitelist?: string[]): boolean {
	return pagesWhitelist ? pagesWhitelist.includes(path) : false;
}

function isPathInBlacklist(path: string, pagesBlacklist?: string[]): boolean {
	return pagesBlacklist ? pagesBlacklist.includes(path) : false;
}

export function evaluateBannerDisplay(
	splitConfig: TopBannerWithLinkConfig,
	path: string,
): boolean {
	if (!Object.keys(splitConfig).length) {
		return false;
	}

	const {
		'badge-text': badgeText,
		'link-text': linkText,
		'link-href': linkHref,
		'pages-whitelist': pagesWhitelist,
		'pages-blacklist': pagesBlacklist,
	} = splitConfig;

	const whitelistExists = hasWhitelist(pagesWhitelist);
	const blacklistExists = hasBlacklist(pagesBlacklist);

	// If there's no text don't show
	if (badgeText === '' && linkText === '' && linkHref === '') {
		return false;
	}

	// If neither whitelist nor blacklist exist, show the banner on all pages
	if (!blacklistExists && !whitelistExists) {
		return true;
	}

	// If only a whitelist exists, show the banner only on those pages
	if (!blacklistExists && whitelistExists) {
		return isPathInWhitelist(path, pagesWhitelist);
	}

	// If only blacklist exists, show the banner on all pages except those in the blacklist
	if (blacklistExists && !whitelistExists) {
		return !isPathInBlacklist(path, pagesBlacklist);
	}

	// If both whitelist and blacklist exist, the blacklist takes precedence
	if (whitelistExists && blacklistExists) {
		if (isPathInBlacklist(path, pagesBlacklist)) return false;
		return isPathInWhitelist(path, pagesWhitelist);
	}

	return false;
}

import UserProfile, {
	UserProfileAttributesMap as UPAM,
	UserProfileData as UPD,
} from './userProfile';

export default function profiler(): [UPD, (key: UPAM, value: string) => void] {
	const user = new UserProfile();

	const updateProfile = (key: UPAM, value: string) => {
		user[key] = value;
	};

	return [user.profile, updateProfile];
}

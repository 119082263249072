import classNames from 'classnames';
import Link from 'next/link';
import { GoogleAnalyticsData } from '@/types/globals';

export interface LinkTiktokProps {
	/** For internal testing using Cypress. */
	data?: GoogleAnalyticsData;

	/** Allows us to add extra styling properties onto the button. */
	className?: string;
}

/** This is the icon that links to the HVAC TikTok. */
const LinkTiktok = (props: LinkTiktokProps) => {
	const { className, data = { testId: 'LinkTiktok' } } = props;

	return (
		<Link
			href={`https://www.tiktok.com/@itshvacdotcom`}
			rel="noopener"
			target="_blank"
			data-testid={data.testId}
		>
			<svg
				className={classNames(
					'fill-slate-400',
					'hover:fill-sky-800',
					className,
				)}
				xmlns="http://www.w3.org/2000/svg"
				fill="#FFF"
				viewBox="0 0 22 22"
				role="img"
				aria-label="Tiktok"
			>
				<path
					fillRule="evenodd"
					d="M15.732 0c.363 3.122 2.106 4.984 5.135 5.182v3.511c-1.755.172-3.293-.403-5.082-1.485v6.568c0 8.343-9.096 10.95-12.753 4.97-2.35-3.848-.91-10.6 6.627-10.871v3.703c-.574.092-1.188.237-1.749.429-1.676.567-2.627 1.63-2.363 3.505.509 3.59 7.096 4.653 6.548-2.364V.008h3.637V0Z"
					clipRule="evenodd"
				/>
			</svg>
		</Link>
	);
};

export default LinkTiktok;

import Link from 'next/link';
import { GoogleAnalyticsData } from '@/types/globals';
import { Variant } from './button.d';
import { getButtonClassname } from './helpers/getButtonClassname';

export interface ButtonLinkProps {
	/** Allows us to change the text of the button. */
	children: React.ReactNode;

	/** Allows us to add extra styling properties onto the button. */
	className?: string;

	/** Allows us to add a hyperlink to the button. */
	href: string;

	/** Gives us different stylings, hover, and focus states of the button. */
	variant?: Variant;

	/** Allows us to set an enabled (clickable) or disabled (unclickable) state for the button. */
	disabled?: boolean;

	/** For internal testing and analytics */
	data?: GoogleAnalyticsData;

	/** Allows us to set the target attribute for the link. */
	target?: string;
}

/** For links that need to look like buttons */
const ButtonLink = (props: ButtonLinkProps) => {
	const {
		children,
		className = '',
		href,
		variant = 'solid',
		disabled = false,
		data = { testId: 'ButtonLink' },
		target,
	} = props;

	const classes = getButtonClassname(variant, disabled, className);

	return (
		<Link
			prefetch={false}
			href={href}
			className={classes}
			target={target}
			data-testid={data.testId}
			data-action={data.action}
			data-location={data.location}
			data-text={data.text}
			data-postion={data.position}
			data-type={data.type}
			data-textgrouping={data.textGrouping}
		>
			{children}
		</Link>
	);
};

export default ButtonLink;
